import * as moment from 'moment';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { Component,  OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'hotel-searches',
  templateUrl: './hotel-searches.component.html',
  styleUrls: ['./hotel-searches.component.scss']
})
export class HotelSearchesComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private _liveAnnouncer: LiveAnnouncer) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public displayedColumns: string[] = [
    'hotelSearchId',
    'memberId',
    'emailId',
    'membershipLevel',
    'displayName',
    'searchLocation',
    'checkinDate',
    'checkoutDate',
    'createDate'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public hotelSearchList: any[] = [];
  public showBookingDetails: boolean = false;
  public bookingDetails: any = {};

  ngOnInit(): void {
    this.getAllHotelSearches();
  }

  public getAllHotelSearches(): void {
    this.dashboardService.getAllHotelSearches().subscribe((res: any) => {
      this.hotelSearchList = res.data.map((d: any) => {
        return {
          ...d,
          emailId: d.member.emailId,
          displayName: d.member.displayName,
          membershipLevel: d.member.membershipLevel
        }
      });
      this.dataSource = new MatTableDataSource<any>(this.hotelSearchList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    },
    (err: any) => {
      this.loading = false;
    });
  }

  public searchItem(): void {
    this.searchControl.setValue(this.searchControl.value.trim())
    const query = this.searchControl.value.toLowerCase();
    const result = this.hotelSearchList.filter((data: any) => {
      return data.memberId?.toString().search(query) !== -1 || data.emailId?.toLowerCase().search(query) !== -1 || data.displayName?.toLowerCase().search(query) !== -1 || data.hotelSearchId?.toString().search(query) !== -1;
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.hotelSearchList.filter((data: any) => {
        return data.memberId.toString().toLowerCase().search('') !== -1 || data.emailId.toLowerCase().search('') !== -1 || data.displayName.toLowerCase().search('') !== -1 || data.hotelSearchId?.toString().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  public getMomentDate(date: string): string {
    return moment(date).format('MMMM DD, YYYY');
  }

  public getMomentDateTime(date: string): string {
    return moment(date).format('MMM DD, YYYY hh:mm A');
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
