import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';
import { ReasonComponent } from '../members/members.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-recent-members',
  templateUrl: './recent-members.component.html',
  styleUrls: ['./recent-members.component.scss']
})
export class RecentMembersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  public displayedColumns: string[] = [
    'memberId',
    'displayName',
    'emailId',
    'membershipLevel',
    'memberCountryCode',
    'createDate',
    'accountStage',
    'status',
    'numYear',
    'vendor',
    'blockUser',
    'unblockUser',
    'downgrade',
    'delete',
    'verifiedMemberFlag'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public membersList: any[] = [];
  public loading: boolean = true;

  ngOnInit(): void {
    this.getAllRecentMembers();
  }

  public getAllRecentMembers(): void {
    this.dashboardService.recentlySignupMembers().subscribe(
      (res: any) => {
        this.loading = false;
        this.membersList = res.data;
        this.dataSource = new MatTableDataSource<any>(this.membersList);
        this.dataSource.paginator = this.paginator;
      },
      (err: any) => {
        this.loading = false;
        this.dataSource = new MatTableDataSource<any>([]);
        this.dataSource.paginator = this.paginator;
        console.log(err);
      }
    );
  }

  public searchItem(): void {
    const query = this.searchControl?.value?.toLowerCase();
    const result = this.membersList.filter((data: any) => {
      return (
        data.memberId.toString().search(query) !== -1 ||
        data.emailId.toLowerCase() === query ||
        data.displayName.toLowerCase().search(query) !== -1
      );
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
  }

  public resetSearch(event: string): void {
    if (!event) {
      this.getAllRecentMembers();
    }
  }

  public blockUser(data: any): void {
    const dialogRef = this.dialog.open(ReasonComponent, {
      data: [data, 'block'],
      autoFocus: false,
      disableClose: true,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getAllRecentMembers();
      }
    });
  }

  public downgradeUser(data: any): void {
    const dialogRef = this.dialog.open(ReasonComponent, {
      data: [data, 'downgrade'],
      autoFocus: false,
      disableClose: true,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.getAllRecentMembers();
      }
    });
  }

  public deleteUser(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to delete ${data.emailId}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId
        };
        this.dashboardService.deleteUser(form).subscribe((res: any) => {
          this.getAllRecentMembers();
        });
      }
    });
  }

  public verifyUser(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to verify ${data.emailId}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          verifiedMemberFlag: 1
        };
        this.dashboardService.memberVerified(form).subscribe((res: any) => {
          this.getAllRecentMembers();
        });
      }
    });
  }
}
